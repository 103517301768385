<template>
<ws-dialog
    v-model="display"
    fill-height
    fullscreen-align="right"
    not-centered
    fullscreen-width="500px"
    width="500px"
    :title="$t('Statistics')"
    hide-save
    :cancel-text="$t('Close')"
>
  <template #header-after >
    <ws-navigation-header
      v-model="navigation"
      :items="navigationItems"
      no-number
      padding="4px"
    />
  </template>

  <template #default>

    <debug-object v-if="pairs.length > 0" :d="pairs[0].data.map(disc => disc.component_id)" />

    <ws-navigation
        v-model="navigation"
        :items="navigationItems"
        hide-header
    >

      <template #item.disciplines>
        <ws-data-table
            :items="disciplinesSelect"
            :headers="disciplinesHeaders"
            disable-pagination
            no-footer
            dense
        >

          <template #item.text="{item}">
            <code> {{ item.value }}</code>
            <h5 :style="`color : ${wsACCENT}`"> {{ item.text }}</h5>
          </template>

          <template #item.hours="{item}">
            <h5>Schedule elements : {{ getDisciplineCount(item.value) }}</h5>
          </template>

        </ws-data-table>


      </template>

      <template #item.teachers>
        <ws-data-table
            :items="teachersSelect"
            :headers="teachersHeaders"
            disable-pagination
            no-footer
            dense
        >
          <template #item.text="{item}">
            <h5 :style="`color : ${wsACCENT}`"> {{ item.text }}</h5>
          </template>
        </ws-data-table>

      </template>


    </ws-navigation>
  </template>





</ws-dialog>
</template>

<script>
export default {
  name: "scheduleStatisticsDialog",
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    entity : {
      type : Object,
      default() { return {} }
    },
    pairs : {
      type : Array,
      default() { return [] }
    },
    disciplinesSelect : {
      type : Array,
      default() { return [] }
    },
    pairTypes : {
      type : Array,
      default() { return [] }
    },
    teachersSelect : {
      type : Array,
      default() { return [] }
    },
    degreeAlias : {
      type : String,
    },
    subgroupsSelect : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      navigation : 'disciplines',
      display : false,
    }
  },
  computed : {
    disciplinesHeaders() {
      return [
        { text : this.$t('Discipline') , value :  'text' },
        { text : this.$t('Hours') , value : 'hours' }
      ]
    },
    teachersHeaders() {
      return [
        { text : this.$t('Teacher') , value :  'text' },
      ]
    },
    navigationItems() {
      return [
        { text : this.$t('Disciplines') , value :  'disciplines' },
        { text : this.$t('Teachers') , value :  'teachers' }
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    }
  },
  methods : {
    getDisciplineCount(value) {
      let items = this.pairs.filter(el => el.data.map(disc => disc.component_id).includes(value) )

      return items.length
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>