<template>
  <dash-page-new
      :title="$t('Group') + ': ' + entity.name"
      :subtitle="$t('Editor')"
      :root="$t('Schedule')"
  >
    <v-sheet :color="wsLIGHTACCENT" class="py-2 px-5 d-flex justify-space-between my-6  wsRoundedLight">
      <schedule-week-selector
          v-model="selectedWeek"
          :pairs="pairs"
          :selected-week="selectedWeek"
      />
      <ws-button v-if="false" @click="displayStatistics = true" label="Statistics" />
    </v-sheet>

    <div v-for="day in 7" :key="day">
      <h3 class="mb-2">{{ $t(`Day_${day}`) }} </h3>


      <schedule-pair-editor
          v-for="(pair , i) in getDayPairs(day)" :key="i + day + 'pair' "
          :pair="pair"
          :disciplines-select="disciplinesSelect"
          :selected-week="selectedWeek"
          :pair-types="pairTypes"
          :teachers-select="teachersSelect"
          :degree-alias="degreeAlias"
          :subgroupsSelect="subgroupsSelect"
          :i="i"
          @new-pair="handleNewPair"
          @update="updatePair"
          @delete="deletePair"
          @blank="handleBlankPair"
          @new-discipline="handleNewTeacherDiscipline"
      />
      <v-sheet v-if="getAvailablePairs(day).length > 0"
               class="pointer noselect d-flex align-center wsRoundedLight py-2 px-4 my-3" :color="wsLIGHTCARD">
        <ft-select
            @input="addPair($event , day)"
            :items="getAvailablePairs(day)"
        >
          <v-icon :color="wsACCENT" class="mr-2">mdi-plus-circle-outline</v-icon>
          <h5 :style="`color : ${wsACCENT}`" >{{ $t('AddPair') }}</h5>
          <v-icon>mdi-menu-down</v-icon>

        </ft-select>

      </v-sheet>



    </div>


    <template #dialog>
      <schedule-statistics-dialog
          v-model="displayStatistics"
          :entity="entity"
          :pairs="pairs"
          :disciplinesSelect="disciplinesSelect"
          :pairTypes="pairTypes"
          :teachersSelect="teachersSelect"
          :degreeAlias="degreeAlias"
          :subgroupsSelect="subgroupsSelect"
      />
    </template>
  </dash-page-new>
</template>

<script>
import schedulePairEditor from "@/components/university/pages/schedule/scheduleEditor/pair/schedulePairEditor";
import scheduleWeekSelector from "@/components/university/pages/schedule/scheduleEditor/scheduleWeekSelector";
import scheduleStatisticsDialog from "@/components/university/pages/schedule/scheduleEditor/scheduleStatisticsDialog";
import {mapActions} from "vuex";

export default {
  name: "scheduleEditor",
  components : {
    scheduleWeekSelector,
    schedulePairEditor,
    scheduleStatisticsDialog
  },
  props : {
    uuid : {
      type : String,
    }
  },
  data() {
    return {
      entity : {},
      pairs : [],
      selectedWeek : 1,
      currentWeekPairs : [],
      disciplinesSelect : [],
      pairTypes : [],
      teachersSelect : [],
      degreeAlias : null,
      displayStatistics : false,
      subgroupsSelect : [],
      dropdown : false
    }
  },
  computed : {
    pairsSelect() {
      return [
        { text : 1 + ' ' +  this.$t('Pair'), value : 1 },
        { text : 2 + ' ' +  this.$t('Pair'), value : 2 },
        { text : 3 + ' ' +  this.$t('Pair'), value : 3 },
        { text : 4 + ' ' +  this.$t('Pair'), value : 4 },
        { text : 5 + ' ' +  this.$t('Pair'), value : 5 },
        { text : 6 + ' ' +  this.$t('Pair'), value : 6 },
        { text : 7 + ' ' +  this.$t('Pair'), value : 7 },
      ]
    }
  },
  methods : {
    ...mapActions('wsu_schedule' , [
        'GET_SCHEDULE_EDITOR',
        'ADD_SCHEDULE_PAIR'
    ]),

    handleBlankPair(pair) {
      this.addPair(pair.number , pair.day , true)
    },
    deletePair(pair) {
      let index = this.pairs.findIndex(el => el.uuid === pair.uuid)
      if ( index === -1 ) {
        return
      }
      this.pairs.splice(index , 1)
    },
    updatePair($event) {
      let index = this.pairs.findIndex(el => el.uuid === $event.uuid )
      this.pairs[index] = this.COPY($event)
      this.pairs = this.COPY(this.pairs)
    },
    handleNewPair($event) {
      this.pairs.push($event)
    },
    handleNewTeacherDiscipline($event) {
      let index = this.teachersSelect.findIndex(el => el.value === $event.teacher)
      if ( index === -1 ) {
        return
      }

      if ( !this.teachersSelect[index].disciplines[this.degreeAlias] ) {
        this.teachersSelect[index].disciplines[this.degreeAlias] = [$event.discipline]
      } else {
        this.teachersSelect[index].disciplines[this.degreeAlias].push($event.discipline)
      }

    },
    async addPair(number , day , blank = false ) {
      let blankPairIndex = this.pairs.findIndex(el => el.number === number && el.day === day && el.week === this.selectedWeek && el.is_blank )
      let data = {
        schedule_id : this.entity.uuid,
        day : day,
        number : number,
        blank : blank,
        week : this.selectedWeek
      }

      if ( blankPairIndex !== -1 ) {
        data.blank_pair_id = this.pairs[blankPairIndex].uuid
      }

      let result = await this.ADD_SCHEDULE_PAIR(data)
      if ( !result ) {
        return this.$t(this.$t('NetworkError') , 'error')
      }
      if ( blankPairIndex !== -1 ) {
        this.pairs.splice(blankPairIndex , 1)
      }
      this.pairs.push(result)
    },
    getDayPairs(day) {
      let items = []
      let dayObject = {}
      let pairs = this.pairs.filter(el => el.day === day && el.week <= this.selectedWeek )
      pairs.forEach(el => {
        if ( !dayObject[el.number] ) {
          dayObject[el.number] = el
        } else if ( el.week > dayObject[el.number].week  ) {
          dayObject[el.number] = el
        }
      })
      Object.keys(dayObject).forEach(number => {
        items.push(dayObject[number])
      })

      return items

    },
    getAvailablePairs(day) {
      let items = this.pairsSelect

      let pairsNumbersArray  = this.getDayPairs(day).filter(el => !el.is_blank).map(el => el.number)

      if ( pairsNumbersArray.length > 0 ) {
        items = items.filter(el => !pairsNumbersArray.includes(el.value))
      }

      return items
    },

    async initPage() {
      let result = await this.GET_SCHEDULE_EDITOR(this.uuid)
      if ( !result ) {
        return
      }
      this.entity = result.schedule
      this.pairs = result.pairs
      this.disciplinesSelect = result.disciplines
      this.pairTypes = result.pair_types
      this.teachersSelect = result.teachers
      this.degreeAlias = result.degree_alias
      this.subgroupsSelect = result.subgroups
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>